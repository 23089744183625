<template>
    <div class="game-com-join-room-window flex-center">
        <confirm-pro ref="joinRoom">
            <div class="join-body">
                <!-- <div class="join-item content">
                    <input type="text" v-model="gameroomCode" @keyup.enter="join" ref="gameroomCodeInput">
                </div> -->
                <div class="join-title flex-center">
                    <span class="text-normal-ba bold">加入房间</span>
                </div>
                <div class="input-content flex-center">
                    <input type="text" v-model="gameroomCode" @keyup.enter="join" ref="gameroomCodeInput" placeholder="请输入6位房间号">
                </div>
                <div class="join-confirm position-center-margin" @click="join" :class="{'disabled-img': confirmDisabled, 'scale-button': !confirmDisabled}">
                    <div class="body flex-center">
                        <span class="text-normal-ab bold">确&nbsp;&nbsp;&nbsp;认</span>
                        <div class="loading position-center" v-if="loading">
                            <van-loading type="spinner" />
                        </div>
                    </div>
                </div>
            </div>
        </confirm-pro>
        
    </div>
</template>
<script>
    import apiClass from '@/API/page/socialGame'

    export default {
        name: '',
        props: {
        },
        data() {
            return {
                api: new apiClass(),
                gameroomCode: null,
                response: null,
                loading: false
            };
        },
        methods: {
            async init(){
                this.response = null
                this.gameroomCode = null
                await this._common.settimeout(500)
                this.$refs.gameroomCodeInput.focus()
            },
            async open(){
                this.init()
                let res = await this.$refs.joinRoom.open({
                    confirmType: 'c18',
                    showClose: true,
                    // buttons: [
                    //     {title: '进入房间', type: 'confirm', label: 0}
                    // ]
                })
                this._voiceHand.normalStop('user_limited')
                await this._common.nextTick.call(this)
                this.$refs.gameroomCodeInput.focus()
                res = res || {}
                if(res.label == 0) return this.join()
                return false
            },
            async join(){
                if(this.confirmDisabled) return
                let {gameroomCode} = this
                this.loading = true
                let res = await this.api.gameroomInfoByCode({gameroomCode, noError: true, noLoad: true})
                this.loading = false
                res = res || {}
                // if(!res.id) return this.api._errorHand.hand({code: '16007'})
                if(!this.checkGameroomStatus(res)) return
                // let {gameroomStatus} = res
                // if(!res.id || gameroomStatus >= this.ENUMS.ROOM_CLOSED) return this.roomClosedNotice()
                this.gotoRoom(res.id)
            },
            checkGameroomStatus(res){
                let {gameroomStateDto: {onlineUserCount} = {}, gameroomStatus} = res || {}
                let f = true, message = null
                if(onlineUserCount >= this.PARAMS.game_room_max_user_num || (gameroomStatus != this.ENUMS.ROOM_CREATED && gameroomStatus < this.ENUMS.ROOM_CLOSED)){
                    f = false
                    message = this._errorCode.game['game room user exceed limit']
                    this._voiceHand.normalPlay('user_limited')
                }else if(gameroomStatus >= this.ENUMS.ROOM_CLOSED || !res.id){
                    f = false
                    message = this._errorCode.common['16007']
                }
                if(!f) this.roomClosedNotice(message)
                return f
            },
            roomClosedNotice(message){
                message = message || this._errorCode.common['16007']
                return this.$toast({
                    message,
                })
            },
            gotoRoom(gameroomId){
                let params = {gameroomId}
                this._routeHand.goto({name: 'player-game-room',  params})
            }
        },
        created() {},
        components: {},
        computed: {
            confirmDisabled(){
                return !this.gameroomCode || this.loading
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .game-com-join-room-window{
        // position: absolute;
        // top: 0px;
        // left: 0px;
        // width: 100%;
        // height: 100%;
        color: #333;
        .com-confirm-pro .gotu-confirm-pro{
            padding-top: 30px;   
        }
        .join-body{
            height: 100%;
            position: relative;
            // padding: 10px;
            // text-align: center;
            // width: 100%;
            // flex-wrap: wrap;
            // border-radius: 10px;
            // .join-item{
            //     width: 100%;
            // }
            // .join-item+ .join-item{
            //     margin-top: 10px;
            // }

            padding-top: 39px;

            .input-content{
                margin-top: 7px;
                input{
                    width: 170px;
                    height: 27px;
                    background: none;
                    border: 0px;
                    padding: 2px 10px;
                }
                .placeholder(){
                    color: #AE9991;
                    font-size: 12px;
                    font-weight: bold;
                }
                input{
                    .placeholder();
                    color: #6F5E65;
                }

                input::placeholder{
                    .placeholder();
                }
                input::-webkit-input-placeholder{   /* 使用webkit内核的浏览器 */
                    .placeholder();
                }
                input:-moz-placeholder{    /* Firefox版本4-18 */
                    .placeholder();
                }              
                input::-moz-placeholder{    /* Firefox版本19+ */
                    .placeholder();
                }              
                input:-ms-input-placeholder{   /* IE浏览器 */
                    .placeholder();
                }     
            }
            .join-title{
                color: #6F5E65;
            }
            .join-confirm{
                width: 129px;
                height: 45px;
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/join/p1_shadow.png'));
                padding: 4px;
                bottom: 12px;
                top: auto;
                .body{
                    color: #B07213;
                    width: 100%;
                    height: 100%;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/join/p1.png'));
                }
                .body{
                    .loading{
                        .van-loading{
                            color: #333;
                        }
                        .van-loading__spinner--spinner{
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
        }
    }
</style>