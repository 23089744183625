<template>
    <div class="com-game-create-room">
        <confirm-pro ref="createRoom">
            <span class="text-normal-c bold" slot="title">创建房间</span>
            <div class="create-body">
                <div class="create-item game-type flex">
                    <div class="title">
                        <span class="text-mini-a nowrap bold">下棋类型</span>
                    </div>
                    <div class="game-type-content">
                        <div class="type-item cursor-pointer scale-button flex-center" 
                        :class="[{'selected': gameTypeSelected.id == v.id}, v.type]"
                        v-for="v in gameListCul" :key="v.id" @click="gameTypeValue = v.id">
                            <div class="main-title flex-center nowrap flex-center">
                                <span class="text-mini-a bold">{{v.label}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="create-item time-type flex">
                    <div class="title">
                        <span class="text-mini-a nowrap bold">保留时间</span>
                    </div>
                    <div class="time-type-content">
                        <div class="type-item cursor-pointer nowrap scale-button flex-center" 
                        :class="{'selected': playerTimeSelected.id == v.id}"
                        v-for="v in playerTimeList" :key="v.id" @click="playerTimeValue = v.id">
                            <span class="text-mini-a bold">{{v.mainTime}}分钟{{v.countdownTime}}秒{{v.countdownNum}}次</span>
                        </div>
                    </div>
                </div>
                <div class="create-confirm cursor-pointer scale-button" @click="confirm" :class="{'loading': loading}">
                    
                    <div class="body flex-center">
                        <span class="text-normal-a bold">确&nbsp;&nbsp;认</span>
                        <div class="loading position-center" v-if="loading">
                            <van-loading type="spinner" />
                        </div>
                    </div>
                </div>
            </div>
        </confirm-pro>
    </div>
</template>
<script>
    import apiClass from '@/API/page/game'
    
    export default {
        name: '',
        props: {
        },
        data() {
            return {
                api: new apiClass(),
                appData: new this.appDataClass({key: 'player-game-create-room'}),
                gameList: [],
                response: null,
                gameTypeValue: null,
                playerTimeValue:null,
                loading: false,
                state: false,
                initResponse: null
            };
        },
        methods: {
            async pageInit(){
                await this.patternListInit()
                this.state = true
                if(this.initResponse) this.initResponse()
                this.initResponse = null
            },
            async patternListInit(){
                let res = await this.api.configLoad('game')
                if(res) this.gameList = res
            },
            pageInitCallback(){
                return new Promise(res => {
                    if(this.state) return res()
                    this.initResponse = res
                })
            },
            async init(){
                await this.pageInitCallback()
                if(!this.gameTypeValue) this.gameTypeInit()
                if(!this.playerTimeValue) this.playerTimeInit()
            },
            async open(){
                await this.init()
                return new Promise(success =>{
                    this.response = success
                    this.$refs.createRoom.open({
                        confirmType: 'c17'
                    })
                })
            },
            close(){
                if(!this.$refs.createRoom) return
                this.$refs.createRoom.close()
            },
            gameTypeInit(){
                let {defaultGameType: {id}} = this
                if(!id) return
                this.gameTypeValue = id
            },
            playerTimeInit(){
                let {defaultPlayerTime: {id}} = this
                if(!id) return
                this.playerTimeValue = id
            },
            async confirm(){
                let {params} = this
                if(!this.gameTypeSelected.id || !this.playerTimeSelected.id) return
                this.loading = true
                let res = await this.api.createRoom({
                    ...params,
                    noLoad: true
                })
                this.loading = false
                if(!res) return this.roomCheck()
                this.close()
                this.gotoRoom(res)
                this.saveConfig()
            },
            gotoRoom(gameroomId){
                let params = {gameroomId}
                this._routeHand.goto({name: 'player-game-room',  params})
            },
            saveConfig(){
                let {gameTypeSelected: {id: gameType}, playerTimeSelected: {id: gameTime}} = this
                this.appData.setItem({gameType, gameTime})
            },
            roomCheck(){
                this._eventBus.$emit('gameLinkTakeFun', {
                    fun: 'gameroomStateCheck',
                })
            },
        },
        created() {
            this.pageInit()
        },
        components: {},
        computed: {
            params(){
                // private Long gameroomId;
                    // private Long wsGameId;

                    // private String gamename;
                    // private String gameType;
                    // private Integer gameRound;

                    // private Integer boardSize;
                    // private String rule;
                    // private Integer stone;
                    // private Float komi;
                    // private Integer handicap;
                    // private Integer guess;

                    // private String blackUserCode;
                    // private String blackClientId;
                    // private Integer blackLevel;
                    // private String whiteUserCode;
                    // private String whiteClientId;
                    // private Integer whiteLevel;

                    // private Long mainTime;
                    // private Long countdownTime;
                    // private Integer countdownNum;

                    // private String currentPlayer;

                    //  --------------------------
                //     private String gamename;
                //     private String gameType;
                //     private Integer gameRound;

                //     private Integer boardSize;
                //     private String rule;
                //     private Integer stone; game_config_default_stone
                //     private Float komi;
                //     private Integer handicap;
                //     private Integer guess;
                // private Long mainTime;
                //     private Long countdownTime;
                //     private Integer countdownNum;
                    
                    let {gamename, gameType, boardSize, rule, komi, startMoveNum} = this
                    let gameRound = 1
                    let handicap = 0
                    let guess = 1
                    let stone = this.PARAMS.game_config_default_stone
                    let mainTime = this.playerTimeSelected.mainTime * 60 * 1000
                    let countdownTime = this.playerTimeSelected.countdownTime * 1000
                    let countdownNum = this.playerTimeSelected.countdownNum
                    // mainTime = 0.1 * 60 * 1000
                    // countdownTime = 15 * 1000
                    // countdownNum = 3
                    let opt = {gamename, gameType, gameRound, boardSize, rule, stone, komi, handicap, guess, mainTime, countdownTime, countdownNum, startMoveNum}
                    return opt

            },
            captureMoves(){
                if(this.isPlay) return []
                // let {boardSize} = this
                
                let {gameTypeSelected: {boardSize}} = this
                let moves = this._common.getCaptureInitBoard(boardSize)
                return moves || []
            },
            isPlay(){
                let {gameTypeSelected: {rule}} = this
                return rule != 'capture'
            },
            isCapture(){
                let {gameTypeSelected: {rule}} = this
                return rule == 'capture'
            },
            startMoveNum(){
                let {captureMoves: moves} = this
                return moves.length
            },
            komi(){
                let {gameTypeSelected: {komi}} = this
                return komi || null
            },
            rule(){
                let {gameTypeSelected: {rule}} = this
                return rule || this.PARAMS.game_default_rule
            },
            boardSize(){
                let {gameTypeSelected: {boardSize}} = this
                return boardSize || null
            },
            gamename(){
                let {gameTypeSelected: {label}} = this
                return label || null
            },
            gameType(){
                let {gameTypeSelected: {type}} = this
                let gameInfo = this.PARAMS.peer_game_info[type] || {}
                let gameType = gameInfo.game_type
                return gameType
            },
            defaultGameType(){
                let id = this.appData.getItem('gameType')
                let {gameListCul: list} = this
                let rd = null
                for(let i in list){
                    if(list[i].id == id && id){
                        rd = list[i]
                        break
                    }
                    if(list[i].default){
                        rd = list[i]
                        // break
                    }
                }
                if(!rd) rd = list[0] || {}
                return rd
            },
            gameListCul(){
                let {gameList: list} = this
                let rd = []
                for(let i in list){
                    let {resourceDetail = {}} = list[i] || {}
                    rd.push({
                        ...list[i],
                        ...resourceDetail
                    })
                }
                return rd
            },
            gameTypeSelected(){
                let {gameListCul: list, gameTypeValue: value} = this
                let {data} = this._common.selected({list, value, key: 'id'})
                return data || {}
            },
            defaultPlayerTime(){
                let id = this.appData.getItem('gameTime')
                let {playerTimeList: list} = this
                let rd = null
                for(let i in list){
                    if(list[i].id == id){
                        rd = list[i]
                        break
                    }
                    if(list[i].default){
                        rd = list[i]
                    }
                }
                if(!rd) rd = list[0] || {}
                return rd
            },
            playerTimeList(){
                let {gameTypeSelected: {playerTime: list = []}} = this
                list = this._common.deepCopy(list)
                for(let i in list){
                    list[i].id = i
                }
                return list
            },
            playerTimeSelected(){
                let {playerTimeValue: value, playerTimeList: list} = this
                let key = 'id'
                let {data} = this._common.selected({list, value, key})
                return data || {}
            },

        },
        watch: {
            playerTimeList: {
                handler(val){
                    this.playerTimeInit()
                }
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .com-game-create-room{
        color: #333;
        .create-room-window{
            padding: 0px 10px;
            padding-top: 30px;
            .win-item+ .win-item{
                margin-top: 10px;
            }
            .win-item{
                display: flex;
                .item-title{
                    margin-right: 10px;
                }
                .item-content{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .type-item.selected{
                        background: #6F83DE;
                        color: #fff;
                    }
                    .type-item{
                        background: #F9BD6B;
                        width: 32%;
                        padding: 10px;
                        margin-top: 5px;
                        span[class^='text']{
                            height: auto;
                            line-height: normal;
                        }
                    }
                }
            }
        }
        .create-body{
            padding: 62px 23px 19px 19px;
            // .selected{
            //     border: 1px solid #333;
            // }
            .create-item{
                justify-content: space-between;
                .title{
                    color: #896B60;
                }
            }
            
            .game-type{
                .title{
                    margin-top: 6px;
                }
                .game-type-content{
                    width: 206px;
                    padding: 5px 6px 0px 5px;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .type-item.capture{
                        width: 94px;
                        height: 30px;
                        color: #CFA65C;
                        .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/create/p1_capture.png'));
                    }
                    .type-item.play{
                        width: 62px;
                        height: 30px;
                        margin-top: 10px;
                        color: #7A8BD9;
                        .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/create/p1_play.png'));
                    }
                    .capture.selected{
                        .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/create/p1_capture_select.png'));
                        color: #B07213;
                    }
                    .play.selected{
                        .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/create/p1_play_select.png'));
                        color: #505A94;
                    }
                }
            }
            .time-type{
                margin-top: 29px;
                .title{
                    margin-top: 2px;
                }
                .time-type-content{
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    padding: 0px 6px 6px 5px;
                    width: 206px;
                    padding: 5px 6px;
                    .type-item{
                        display: flex;
                        height: 20px;
                        width: 45%;
                        color: #9C8BDA;
                        margin-top: 10px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/create/p1_time.png'));
                    }
                    .type-item:nth-child(1){
                        margin-top: 0px;
                    }
                    .type-item:nth-child(2){
                        margin-top: 0px;
                    }
                    .type-item.selected{
                        color: #7A629A;
                        .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/create/p1_time_select.png'));
                    }
                }

            }
            .create-confirm.loading{
                filter: grayscale(1);
            }
            .create-confirm{
                position: absolute;
                bottom: 14px;
                left: 0px;
                right: 0px;
                margin: auto;
                width: 142px;
                height: 49px;
                padding: 5px;
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/create/p2_shadow.png'));
                .body{
                    color: #B07213;
                    width: 133px;
                    height: 40px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/create/p2.png'));
                }
                .loading{
                    .van-loading{
                        color: #333;
                    }
                    .van-loading__spinner--spinner{
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }
</style>