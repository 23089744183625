<template>
    <div class="page-game-player-summary invite-page flex-center">
        <!-- <div class="player-sum-item scale-button flex-center" v-for="v in gameList" :key="v.value" :class="v.value" @click="action(v)">
            <span class="text-large-d bold">{{v.name}}</span>
        </div> -->
        <component-bcg url="game/socialGame/invite/p1.png"></component-bcg>
        <div class="in-bcg">
            <div class="bcg-item b"></div>
            <div class="bcg-item s">
                <div class="s-body"></div>
            </div>
        </div>
        <div class="in-body">
            <div class="player-sum-item scale-button flex-center" v-for="v in gameList" :key="v.value" :class="v.value" @click="action(v)">
                <div class="body">
                    <span class="text-small text bold">{{v.intro}}</span>
                </div>
            </div>
        </div>
        <createRoomWin ref="createRoomWin"></createRoomWin>
        <joinRoomWin ref="joinRoomWin"></joinRoomWin>
    </div>
</template>
<script>
    import createRoomWin from './components/createRoom.vue'
    import joinRoomWin from './components/joinRoomWin.vue'

    export default {
        name: '',
        props: {
        },
        data() {
            return {
                gameList: [
                    {name: '创建房间', value: 'create', intro: '邀请好友来对战'},
                    {name: '加入房间', value: 'join', intro: '使用房间号进入'},
                ]
            };
        },
        methods: {
            action(val){
                let {value} = val ||{}
                if(!this[value]) return
                this[value](val)
            },
            create(){
                this.$refs.createRoomWin.open()
            },
            join(){
                this.$refs.joinRoomWin.open()
            }
        },
        created() {},
        components: {
            createRoomWin,
            joinRoomWin
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .page-game-player-summary{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        .in-bcg{
            position: fixed;
            width: @designWidth;
            left: 0px;
            top: 0px;
            z-index: 0;
            .bcg-item{
                position: absolute;
            }
            .b{
                width: 390px;
                height: 262px;
                right: 0px;
                top: 0px;
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/p2.png'));
            }
            .s{
                width: 100%;
                height: 194px;
                left: 0px;
                top: 0px;
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/p3.png'));
                .s-body{
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 12px;
                    width: 82px;
                    height: 20px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/p7.png'));
                }
            }
            .bo{
                width: 655px;
                
            }
        }


        .in-body{
            position: absolute;
            z-index: 1;
            bottom: 0px;
            left: 10px;
            width: 655px;
            height: 155px;
            .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/p4.png'));
            .player-sum-item{
                position: absolute;
                bottom: 25px;
                top: auto;
                .body{
                    bottom: 10px;
                    top: auto;
                    width: 195px;
                    height: 287px;
                    position: absolute;
                    .text{
                        color: #FFF3E2;
                        position: absolute;
                        bottom: 29px;
                        left: 54px;
                    }
                }
            }
            .create{
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/p5_shadow.png'));
                width: 212px;
                height: 299px;
                left: 91px;
                .body{
                    left: 15px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/p5.png'));
                }
            }
            .join{
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/p6_shadow.png'));
                width: 213px;
                height: 297px;
                left: 333px;
                .body{
                    left: 16px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/invite/p6.png'));
                }
            }
        }
    }
</style>